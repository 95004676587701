import tw from "twin.macro"
import styled from "@emotion/styled/macro"

import { StyledLink } from "../../../Styled/Button"

export const Wrapper = styled.div`
  ${tw`w-full px-20`}
  .swiper-slide {
    ${tw`h-auto`}
  }
`
export const Arrow = styled(StyledLink)<ArrowProps>`
  ${tw`absolute z-1 w-6 h-6 md:w-8 md:h-8 flex items-center justify-center`}
  ${({ position }) => (position === `right` ? tw`right-0` : tw`left-0`)}
`
export const Arrows = tw.div`relative mt-1/2 md:mt-1/4 lg:mt-0`

export const ArrowWrapper = tw.div`absolute inset-0 flex items-start lg:items-center justify-center`

interface ArrowProps {
  position: "right" | "left"
}

export const Container = tw.div`flex flex-wrap`
export const ProductCardContainer = tw.div` w-1/2 md:w-1/3 xl:w-1/4 h-auto px-3.5`
