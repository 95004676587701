import tw from "twin.macro"
import styled from "@emotion/styled/macro"
import { P, H5 } from "../../../Styled/Text"

import { GlobalStyles, GlobalStylesTypes } from "../../../Styled/Global"
import { StyledButton, StyledLink } from "../../../Styled/Button"

export const Card = styled.section<CardProps>`
  ${tw`w-full h-95/100 flex flex-col shadow-xs justify-start items-start flex-grow border-2 border-orange-light animate-grow transition-all duration-500`}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}
  ${({ highlight }) => (highlight === "true" ? tw`border-opacity-100` : tw`border-opacity-0`)}
`
export const Close = styled(StyledLink)`
  ${tw`absolute top-0 right-0 p-3`}

  svg {
    ${tw`w-4 md:w-5`}
  }
`

export const Title = styled(H5)`
  ${tw`pb-2`}
  word-break: break-word;
`

export const ImageContent = styled.div<ImageContentProps>`
  ${tw`w-full`}
  ${({ clickable }) => (clickable === "true" ? tw`cursor-pointer` : "")}
`
export const ImageContainer = styled.div<ImageContainerProps>`
  ${tw`relative mb-5.5 w-full`}
  ${({ colour }) => GlobalStyles.background[colour]}
`
export const Content = tw.div`flex flex-col w-full h-full justify-between`
export const TextContent = tw.div`flex flex-col w-full h-full justify-start px-2 pb-3`
export const TitleGroup = tw.div``
export const ProductContent = styled.div<GenericContentProps>`
  ${tw`w-full`}
  ${({ withSpacing }) => (withSpacing && GlobalStyles.spacing[withSpacing]) || (withSpacing && GlobalStyles.spacing[`default`])}
`
export const ButtonGroup = tw.div`flex flex-col md:flex-row w-full justify-between mb-4`
export const ButtonWrapper = tw.div`w-full md:w-10/12 lg:w-7/12 px-1.5`
export const ButtonWrapperLearnMore = tw.div`w-full md:w-1/2 `
export const Button = tw(StyledButton)`mb-2 md:mb-0`
export const ButtonLink = tw(StyledLink)`mb-2 md:mb-0 underline pt-2`
export const Image = styled.div<ImageProps>`
  ${tw`absolute inset-0 first:static first:inset-auto transition-all duration-200`}
  ${({ hover }) => hover && tw`opacity-0 invisible`}
`

export const ContentGroup = tw.div`flex items-start justify-start`
export const ContentBulletPoint = tw(P)`block mr-2 md:ml-2`

interface CardProps extends GenericContentProps {
  highlight?: "true" | undefined
}

interface ImageContentProps {
  clickable?: "true" | undefined
}
interface GenericContentProps {
  withSpacing?: GlobalStylesTypes["spacing"]
}
interface ImageProps {
  hover?: boolean
}
interface ImageContainerProps {
  colour: GlobalStylesTypes["background"]
}
