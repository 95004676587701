import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"

import { withPickGiftSelectFooter } from "./withPickGiftSelectFooter"
import { PickGiftAddToCart } from "../AddToCart/PickGiftAddToCart"
import { Icon } from "../../../Icon/Icon"
import { StyledColumn } from "../../../Styled/Column"
import {
  Footer,
  Close,
  Container,
  Inner,
  ImageGroup,
  ImageWrapper,
  Image,
  AspectRatio,
  ButtonGroup,
  Button,
  Link,
  LinkText,
  TitleGroup,
  Total,
  Amount,
  Placeholder,
  Tooltip,
  Toolwrapper,
} from "./styledPickGiftSelectFooter"

export const PickGiftSelectFooter = withPickGiftSelectFooter(
  ({
    locales,
    selectedProducts,
    selectedProductsReversed,
    buttonDisabled,
    displayNextButton,
    displayBackButton,
    displayAddToCartButton,
    displayCardNextButton,
    placeholderItems,
    displayTotal,
    AddToCartTitle,
    isMedium,
    totalPrice,
    reviewId,
    active,
    step,
    hidePrice,
    custCardInfo,
    cardSelector,
    handleRemoveProduct,
    handleClick,
    handleClickSkip,
    handleClickBack,
    handleItem,
  }) => (
    <Footer>
      <Container width={`xl`}>
        <Inner>
          {!isMedium && displayBackButton && (
            <ButtonGroup>
              <StyledColumn gutter={`sm`} width={`no-shrink`}>
                <Link onClick={handleClickBack} title={"Back"}>
                  <Icon name={`left`} title={"Back"} width={12} />
                </Link>
              </StyledColumn>
            </ButtonGroup>
          )}
          <ImageGroup>
            {/* {displayImages && ( */}
            <>
              <Swiper
                autoplay={false}
                lazy
                loop={false}
                mousewheel={true}
                allowTouchMove={true}
                navigation={false}
                preloadImages={false}
                spaceBetween={0}
                slidesPerView={"auto"}
                dir={"rtl"}
                watchSlidesProgress={true}
              >
                {selectedProductsReversed.map((product, index) =>
                  product ? (
                    <SwiperSlide key={index}>
                      <ImageWrapper key={index} onClick={() => !isMedium && handleRemoveProduct(product?.handle)}>
                        {product?.images?.length ? (
                          <Image alt={product?.title} fluid={{ ...product?.images?.[0], aspectRatio: 1 }} />
                        ) : (
                          <AspectRatio />
                        )}
                        <Close onClick={() => isMedium && handleRemoveProduct(product?.handle)} title={"close"}>
                          <Icon name={`close`} width={4} />
                        </Close>
                      </ImageWrapper>
                    </SwiperSlide>
                  ) : (
                    ""
                    // <ImageWrapper key={index}>
                    //   <Placeholder>{index}</Placeholder>
                    // </ImageWrapper>
                  )
                )}
              </Swiper>
              {placeholderItems.map(item => (
                <ImageWrapper key={item}>
                  <Placeholder>{item}</Placeholder>
                </ImageWrapper>
              ))}
            </>
            {/*  )} */}

            {displayTotal && isMedium && hidePrice && (
              <TitleGroup>
                <Total>{locales?.additionalTotal}</Total>
                <Amount>{`$${totalPrice}`}</Amount>
              </TitleGroup>
            )}
          </ImageGroup>
          <ButtonGroup>
            {isMedium && displayBackButton && (
              <StyledColumn gutter={`sm`} width={`no-shrink`}>
                <Link onClick={handleClickBack} title={"Back"}>
                  <Icon name={`left`} title={"Back"} width={12} /> <LinkText>{"Back"}</LinkText>
                </Link>
              </StyledColumn>
            )}
            {displayCardNextButton && (
              <Toolwrapper onMouseEnter={(event: any) => handleItem(event)} onMouseLeave={(event: any) => handleItem(event)}>
                <Button
                  colour={`orange`}
                  onClick={cardSelector == true ? handleClick : handleClickSkip}
                  size={`secondary`}
                  title={locales?.additionalNext}
                >
                  {displayTotal && !isMedium && hidePrice ? `$${totalPrice}` + " -" : ""} {cardSelector == true ? "Next" : "Skip"}
                </Button>
              </Toolwrapper>
            )}
            {displayNextButton && (
              <Toolwrapper onMouseEnter={(event: any) => handleItem(event)} onMouseLeave={(event: any) => handleItem(event)}>
                {step === 1 && active === true && buttonDisabled == true && <Tooltip> {locales.minimumSpendPopUp} </Tooltip>}
                <Button colour={`orange`} onClick={handleClick} size={`secondary`} title={locales?.additionalNext} disabled={buttonDisabled}>
                  {displayTotal && !isMedium && hidePrice ? `$${totalPrice}` + " -" : ""}{" "}
                  {step === 1 ? locales?.additionalPackPresent : locales?.additionalNext}
                </Button>
              </Toolwrapper>
            )}
            {/* {displayNextIcon && (
              <StyledColumn gutter={`sm`} width={`no-shrink`}>
                <Link onClick={handleClick} title={"Back"} disabled={buttonDisabled}>
                  <Icon name={`right`} title={"Back"} width={12} />
                </Link>
              </StyledColumn>
            )} */}
            {displayAddToCartButton && (
              <PickGiftAddToCart
                custCardInfo={custCardInfo}
                reviewId={reviewId}
                selectedProducts={selectedProducts}
                buttonTitle={AddToCartTitle}
                buttonDisabled={buttonDisabled}
              />
            )}
          </ButtonGroup>
        </Inner>
      </Container>
    </Footer>
  )
)
