import React, { memo, useCallback, useState } from "react"

import * as Components from "../../../../types/components"
import { PickGift } from "../../../../types/pickGift"
import { useDom } from "../../../../hooks/useDom"

export const withPickGiftRefine = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = "PickGiftSelectRefine", items, selectedRefine, handleClick }: ExternalProps) => {
    const { dom } = useDom()

    const [active, setActive] = useState("Refine by")
    const isMedium = dom?.isMedium
    const activeIndex = items?.map(item => item.productTag)?.indexOf(selectedRefine?.productTag) || 0

    const options = items.map(item => ({ label: item?.title, value: item?.productTag }))

    const handleChange = useCallback(
      ({ target: { value } }) => {
        handleClick(value)

        for (let index = 0; index < options.length; index++) {
          if (options[index].value == value) {
            setActive(options[index].label)
            return
          } else {
            setActive("All products")
          }
        }
      },
      [options, handleClick]
    )

    const props = {
      items,
      activeIndex,
      isMedium,
      options,
      active,
      handleClick,
      handleChange,
    }

    Component.displayName = name
    return <Component {...(props as P)} />
  })

type Option = {
  label: string
  value: string
}

type ExternalProps = Components.ComponentProps & {
  items: PickGift.Item[]
  selectedRefine: PickGift.Item
  handleClick: (tag: string) => void
}
type InjectedProps = {
  items: PickGift.Item[]
  activeIndex: number
  isMedium: boolean
  options: Option[]
  handleClick: (handle: string) => void
  handleChange: (input: any) => void
}
