import React from "react"
import { graphql } from "gatsby"

import { PickGift as Page } from "../components/PickGift/PickGift"

export const query = graphql`
  query {
    page: sanityPagePickGift {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
    }
    pickGift: sanityPagePickGift {
      boxes: _rawBoxes(resolveReferences: { maxDepth: 4 })
      bags: _rawBags(resolveReferences: { maxDepth: 4 })
      products: _rawCollection(resolveReferences: { maxDepth: 4 })
      cards: _rawCards(resolveReferences: { maxDepth: 4 })
      filter: _rawFilter(resolveReferences: { maxDepth: 4 })
      refine: _rawRefine(resolveReferences: { maxDepth: 4 })
      links: _rawLinks(resolveReferences: { maxDepth: 4 })
    }
    locales: sanityPagePickGift {
      additionalStart
      additionalNext
      additionalPackPresent
      additionalAddToCart
      additionalUpdateCart
      additionalLearnMore
      additionalRemove
      addToBag
      addedToBag
      additionalSelect
      additionalSelectAlternative
      additionalSelected
      additionalSoldOut
      additionalStep
      additionalStepOne
      additionalStepTwo
      additionalStepThree
      additionalStepFour
      additionalStepFive
      additionalProductInfoHeadingOne
      additionalProductInfoHeadingTwo
      additionalProductInfoHeadingThree
      additionalTotal
      popUpOneText
      popUpOneButton
      minimumSpendPopUp
      custCardMessageHeading
      custCardMessageDescription
      landingBannerTitle
      landingBannerSubtitle
      landingBannerImage: _rawLandingBannerImage(resolveReferences: { maxDepth: 2 })
      video: _rawVideo(resolveReferences: { maxDepth: 2 })
      landingBannerSubtitle
      landingBannerText
      landingBannerBackground
      headerBannerTitle
      headerBannerText
      headerBannerBackground
    }
    products: allSanityProduct(filter: { shopify: { shopifyDeleted: { ne: true }, shopifyPublished: { eq: true } } }) {
      edges {
        node {
          id: _id
          tags
          title
          shopify {
            shopifyHandle
          }
          metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
          content {
            why: _rawWhy(resolveReferences: { maxDepth: 2 })
            ingredients {
              description
              id
              title
            }
            recipe {
              description
              icon
              id
              title
            }
            smells {
              id
              image: _rawImage(resolveReferences: { maxDepth: 2 })
              title
            }
            feels {
              id
              image: _rawImage(resolveReferences: { maxDepth: 2 })
              title
            }
            suitability {
              id
              title
            }
            usage {
              id: _key
              content: _rawContent(resolveReferences: { maxDepth: 2 })
              title
            }
            bulletPoints
            coverImage: _rawCoverImage(resolveReferences: { maxDepth: 4 })
            animationImage: _rawAnimationImage(resolveReferences: { maxDepth: 4 })
          }
        }
      }
    }
    template: sanityTemplateProduct {
      banned
      brand: _rawBrand(resolveReferences: { maxDepth: 2 })
      features
      recommendationsArrows
      recommendationsAutoplay
      recommendationsAutoplaySeconds
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
      additionalFaqs
      additionalFaqsShort
      additionalIngredients
      additionalBanned
      additionalPause
      additionalPlay
      additionalFeels
      additionalSmells
      additionalSuitability
      additionalRelated
      additionalReviews
      additionalReviewsEmpty
      additionalReviewsName
      additionalReviewsEmail
      additionalReviewsLocation
      additionalReviewsTitle
      additionalReviewsContent
      additionalReviewsSubmit
      additionalReviewsSuccess
      additionalReviewsWrite
      additionalQuantityAdd
      additionalQuantityRemove
      additionalAddToCart
      additionalNotify
      additionalNotifyTitle
      additionalAfterpay
      additionalDescription
      additionalIngredientsButton
      additionalWhy
    }
  }
`

const Component = ({ data, ...props }): JSX.Element => <Page {...props} {...data} />

export default Component
