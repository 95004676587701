import tw from "twin.macro"
import styled from "@emotion/styled/macro"
import { H4, H5, RichText } from "../../Styled/Text"

import { StyledContainer } from "../../Styled/Container"
import { StyledForm } from "../../Styled/Form"

export const Form = tw(StyledForm)`flex flex-col items-stretch`
export const custMessageContainer = tw.div`flex flex-col`
export const Inner = tw.div`max-w-screen-xl mx-auto md:mt-0 md:mb-10`
export const Container = styled(StyledContainer)`
  ${tw`relative`}

  .css-sctxzp-ProductCardContainer {
    width: 22% !important;
  }

  .css-e47j2m-Container {
    justify-content: center !important;
  }
  .css-1q0xlzh-FormWrapper {
    width: 94%;
  }
`

export const ContentWrapper = styled(StyledContainer)`
  ${tw`mb-12 md:text-center`}

  @media only screen and (max-width: 475px) {
    padding-right: 0;
  }
`

export const MessageHeading = styled(H4)`
  ${tw`text-lg text-orange-light text-center pb-2`}
`
export const MessageSubheading = styled(H5)`
  ${tw`text-center pb-4 font-medium`}
`
export const Div = styled(RichText)`
  ${tw`flex justify-center`}

  max-width: 1440px;
  margin: 0 auto;

  .messageTextarea {
    width: 55%;
    -webkit-appearance: none;
    box-shadow: 0 0 10px #d3d3d3;
    -webkit-box-shadow: 0 0 10px #d3d3d3;
    margin-bottom: 55px;
    height: 20rem;
    padding: 25px;
  }

  .messageTextarea:focus {
    outline: none;
  }

  @media only screen and (max-width: 900px) {
    .messageTextarea {
      width: 80%;
    }
  }

  @media only screen and (max-width: 525px) {
    .messageTextarea {
      width: 90%;
    }
  }
`
