import React, { memo, useCallback, useMemo, useState, useEffect } from "react"

import * as Components from "../../../types/components"
import * as Sanity from "../../../types/sanity"
import { PickGift } from "../../../types/pickGift"
import { useApp } from "../../../hooks/useApp"
import { useDom } from "../../../hooks/useDom"
import { usePickGift } from "../../../hooks/usePickGift"
import { useShopify } from "../../../hooks/useShopify"
import { useRoutes } from "../../../hooks/useRoutes"
import { useCheckout } from "../../../hooks/useCheckout"

export const withPickGiftSelect = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(
    ({
      name = "PickGiftSelect",
      location,
      locales,
      page,
      pickGiftBoxes,
      pickGiftBags,
      pickGiftProducts,
      pickGiftCards,
      pickGiftFilter,
      pickGiftRefine,
      products,
      template,
    }: ExternalProps) => {
      const {
        config: {
          settings: { params, customAttributeKeys },
        },
        helpers: { edgeNormaliser },
      } = useApp()
      const { checkout } = useCheckout()
      const { useCollections } = useShopify()
      const { getSelectableProducts, getSelectableProductsRefine, mergeProducts, convertFilter } = usePickGift()
      const {
        dom: { isMedium, isMediumLarge, isExtraLarge },
      } = useDom()
      const { getUrlParameter } = useRoutes()

      const [activated, setActivated] = useState(false)
      const [skippedCard, setSkippedCard] = useState(false)

      const [custCardInfo, setCustCardInfo] = useState("")
      const reviewId = getUrlParameter(params.review, location)
      const lineItems = checkout?.lineItems

      // Checks to see if you're editing your gift
      if (reviewId != null) {
        // Loops all products in the cart
        for (let index = 0; index < lineItems?.length; index++) {
          // Checks to see if customer card info has been found before (Stops this from happening multiple times)
          if (custCardInfo == "") {
            // Loops custom attributes for products in cart
            for (let index2 = 0; index2 < lineItems[index]?.customAttributes?.length; index2++) {
              // Checks to see if review id is correct to the one in the URL and if a customer card line item property exists
              if (
                lineItems[index]?.customAttributes[lineItems[index]?.customAttributes.length - 1]?.value == reviewId &&
                lineItems[index]?.customAttributes[index2]?.key == "_customer_card_text"
              ) {
                setCustCardInfo(lineItems[index]?.customAttributes[index2]?.value)
                break
              }
            }
          } else {
            break
          }
        }
      }

      const handleSetActivated = useCallback(() => {
        if (activated === true) {
          setActivated(false)
        } else {
          setActivated(true)
        }
      }, [setActivated, activated])

      const selectedHandles =
        lineItems?.reduce((result, item) => {
          const groupedId = item?.customAttributes?.find(({ key }) => key === customAttributeKeys.grouped)?.value

          if (reviewId && groupedId && groupedId === reviewId) {
            if (result[item?.product?.handle]) {
              result[item?.product?.handle] = result[item?.product?.handle] + item.quantity
            } else {
              result[item?.product?.handle] = item.quantity
            }
          }
          return result
        }, {}) || {}

      const STEPS = useMemo(
        () => ({
          selectBox: 0,
          selectProducts: 1,
          selectBag: 2,
          selectCards: 3,
          custMessage: 4,
          reviewBag: 5,
        }),
        []
      )

      const filters = convertFilter(pickGiftFilter)
      const refine = convertFilter(pickGiftRefine)
      const allSanityProducts: Array<Sanity.Product> = edgeNormaliser(products)

      const boxProductByCollection = useCollections({
        firstImages: 10,
        firstProducts: 10,
        firstVariants: 1,
        handles: pickGiftBoxes ? [pickGiftBoxes?.shopify?.shopifyHandle] : null,
      })

      const bagProductByCollection = useCollections({
        firstImages: 10,
        firstProducts: 10,
        firstVariants: 1,
        handles: pickGiftBags ? [pickGiftBags?.shopify?.shopifyHandle] : null,
      })

      const productByCollection = useCollections({
        firstImages: 10,
        firstProducts: 100,
        firstVariants: 1,
        handles: pickGiftProducts ? [pickGiftProducts?.shopify?.shopifyHandle] : null,
      })

      const cardProductByCollection = useCollections({
        firstImages: 10,
        firstProducts: 50,
        firstVariants: 1,
        handles: pickGiftCards ? [pickGiftCards?.shopify?.shopifyHandle] : null,
      })

      const boxes = useMemo(
        () => mergeProducts(boxProductByCollection?.[0]?.products, allSanityProducts),
        [allSanityProducts, boxProductByCollection, mergeProducts]
      )

      const bags = useMemo(
        () => mergeProducts(bagProductByCollection?.[0]?.products, allSanityProducts),
        [allSanityProducts, bagProductByCollection, mergeProducts]
      )

      const cards = useMemo(
        () => mergeProducts(cardProductByCollection?.[0]?.products, allSanityProducts),
        [allSanityProducts, cardProductByCollection, mergeProducts]
      )

      const selectableProducts = getSelectableProducts({
        filters: filters,
        shopifyProducts: productByCollection?.[0]?.products || [],
        sanityProducts: allSanityProducts,
      })

      const selectableProductsRefine = getSelectableProductsRefine({
        refine: refine,
        shopifyProducts: productByCollection?.[0]?.products || [],
        sanityProducts: allSanityProducts,
      })

      const currentSelectedBag: PickGift.SelectableProduct = bags.find(bag => selectedHandles[bag.handle])
      const currentSelectedCard: PickGift.SelectableProduct = cards.find(card => selectedHandles[card.handle])
      const currentSelectedBox: PickGift.SelectableProduct = boxes.find(box => selectedHandles[box.handle])

      const currentSelectedProducts: PickGift.SelectableProduct[] = selectableProducts["All products"]?.reduce((result, product) => {
        if (selectedHandles[product?.handle]) {
          for (let i = 0; i < selectedHandles[product?.handle]; i++) {
            result.push(product)
          }
        }
        return result
      }, [])

      if (currentSelectedBag) {
        currentSelectedProducts.unshift(currentSelectedBag)
      }

      if (currentSelectedCard) {
        currentSelectedProducts.unshift(currentSelectedCard)
      }

      if (currentSelectedBox) {
        currentSelectedProducts.unshift(currentSelectedBox)
      }

      const [step, setStep] = useState<number>(reviewId ? STEPS.reviewBag : STEPS.selectBox)
      const [selectedFilter, setSelectedFilter] = useState<PickGift.Item>(filters[0])
      const [selectedRefine, setSelectedRefine] = useState<PickGift.Item>(refine[0])
      const [selectedProducts, setSelectedProducts] = useState<Array<PickGift.SelectableProduct>>(
        currentSelectedProducts.length > 3 ? currentSelectedProducts : []
      )

      const [activeProduct, setActiveProduct] = useState<PickGift.SelectableProduct | null>(null)
      const [activeDrawer, setActiveDrawer] = useState<boolean>(false)
      const [bagChecked, setBagChecked] = useState(null)
      const [boxChecked, setBoxChecked] = useState(null)

      const activeProducts = useMemo(() => {
        switch (step) {
          case STEPS.selectBox:
            return boxes
          case STEPS.selectProducts:
            // eslint-disable-next-line
            let filtered = selectableProducts[selectedFilter?.title || "All products"]
            // eslint-disable-next-line
            let refined = selectableProductsRefine[selectedRefine?.title || "All products"]

            // eslint-disable-next-line
            let finalProducts = []

            if (
              (selectedFilter?.title == "All products" && selectedRefine?.title == "All products") ||
              (selectedFilter?.title !== "All products" && selectedRefine?.title == "All products") ||
              (selectedFilter?.title == "All products" && selectedRefine?.title == null) ||
              (selectedFilter?.title !== "All products" && selectedRefine?.title == null)
            ) {
              finalProducts = filtered
            } else {
              for (let option = 0; option < filtered.length; option++) {
                for (let index = 0; index < refined.length; index++) {
                  if (filtered[option]?.handle === refined[index].handle) {
                    finalProducts.push(refined[index])
                  }
                }
              }
            }

            return finalProducts

          case STEPS.selectBag:
            return bags
          case STEPS.selectCards:
            return cards
          case STEPS.custMessage:
            return cards
          case STEPS.reviewBag:
            return selectedProducts?.slice().filter(p => p)
          default:
            return bags
        }
      }, [
        STEPS,
        boxes,
        bags,
        cards,
        selectableProducts,
        selectableProductsRefine,
        selectedFilter?.title,
        selectedProducts,
        step,
        selectedRefine?.title,
      ])

      const displaySlider = useMemo(() => {
        const length = activeProducts?.length || 0
        const needSlider =
          (isExtraLarge && length > 4) || (isMediumLarge && !isExtraLarge && length > 3) || (!isMediumLarge && !isExtraLarge && length > 2)

        return isMedium && needSlider && step !== STEPS.reviewBag
      }, [STEPS.reviewBag, activeProducts?.length, isExtraLarge, isMedium, isMediumLarge, step])

      const selectedProductCount = selectedProducts.reduce((result, item) => {
        if (item) {
          result[item.id] = result[item.id] ? result[item.id] + 1 : 1
        }

        return result
      }, {})

      const handleClickProductInfo = useCallback((product: PickGift.SelectableProduct) => {
        setActiveProduct(product)
      }, [])

      const handleCloseDrawer = useCallback(() => {
        setActiveDrawer(false)
      }, [])

      const handleCardInfo = useCallback(
        event => {
          setCustCardInfo(event?.target?.value)
        },
        [setCustCardInfo]
      )

      const handleClickFilter = useCallback(
        (tag: string) => {
          const find = filters.find(item => item.productTag === tag)
          setSelectedFilter(find ? find : null)
        },
        [filters]
      )

      const handleClickRefine = useCallback(
        (tag: string) => {
          const find = refine.find(item => item.productTag === tag)
          setSelectedRefine(find ? find : null)
        },
        [refine]
      )

      const handleSkip = useCallback(
        // eslint-disable-next-line
        (index?: number) => {
          setStep(5)
          setSkippedCard(true)
        },
        [setSkippedCard]
      )

      const handleNext = useCallback(
        (index?: number) => {
          if (index !== undefined) {
            setStep(index)
            setActivated(false)
          } else {
            setStep(prev => (prev < Object.keys(STEPS).length - 1 ? prev + 1 : prev))
            setActivated(false)
            setSkippedCard(false)
          }
        },
        [STEPS, setSkippedCard]
      )

      const handleGoBack = useCallback(
        (index?: number) => {
          if (index !== undefined) {
            setStep(index)
          } else if (skippedCard === true) {
            setStep(3)
            setSkippedCard(false)
          } else {
            setStep(prev => (prev > 0 ? prev - 1 : prev))
          }
        },
        [skippedCard, setSkippedCard]
      )

      const handleSelectProduct = useCallback(
        (product: PickGift.SelectableProduct) => {
          const currentProducts = [...selectedProducts]

          if (product?.tags?.includes("Pick+Gift:Bag")) {
            setBagChecked(true)
          }

          if (product?.tags?.includes("Pick+Gift:Box")) {
            setBoxChecked(true)
          }

          // This checks to see if a bag or card is already present in the selected products and removes it so we can only have 1 of each
          if (step === STEPS.selectBag || step === STEPS.selectCards) {
            let productTag
            for (let index = 0; index < product?.tags.length; index++) {
              if (product?.tags[index].includes("Pick+Gift")) {
                productTag = product?.tags[index]
              }
            }

            for (let index = 0; index <= currentProducts?.length; index++) {
              currentProducts[index]?.tags?.includes(productTag)
              if (currentProducts[index]?.tags?.includes(productTag)) {
                currentProducts.splice(index, 1)
              }
            }
          }

          if (step === STEPS.selectBox) {
            if (currentProducts[0]?.tags?.includes("Pick+Gift:Box")) {
              currentProducts.shift()
            }

            currentProducts.unshift(product)
            setSelectedProducts(currentProducts)
          } else {
            const bag = currentProducts.shift()
            const indexNull = currentProducts.indexOf(null)
            if (indexNull >= 0) {
              currentProducts.splice(indexNull, 1, product)
            } else {
              currentProducts.push(product)
            }
            currentProducts.unshift(bag)
            setSelectedProducts(currentProducts)
          }

          if ((step === STEPS.selectBag || step === STEPS.selectBox) && currentProducts.filter(p => p).length > 5) {
            handleNext(2)
          }
        },
        [STEPS.selectBag, STEPS.selectBox, STEPS.selectCards, handleNext, selectedProducts, step]
      )

      const handleRemoveProduct = useCallback(
        (productHandle: string) => {
          const currentProducts = [...selectedProducts]
          const productHandles = currentProducts.map(product => product?.handle)
          const index = productHandles.indexOf(productHandle)
          const productDetails = currentProducts[index]
          currentProducts.splice(index, 1, null)
          const bagItem = currentProducts.shift()

          // Checks to see if it is the bag being removed to take the user back to the "select bag" step
          if (productDetails?.tags?.includes("Pick+Gift:Bag")) {
            setBagChecked(false)
          }

          if (productDetails?.tags?.includes("Pick+Gift:Box")) {
            setBoxChecked(false)
          }

          const filteredProducts = currentProducts.sort((a, b) => {
            // nulls sort after anything else
            if (a === null) {
              return 1
            } else if (b === null) {
              return -1
            } else {
              return 0
            }
          })

          filteredProducts.unshift(bagItem)

          // Used to remove nulls from the selected products array
          const filteredProductsV2 = []
          for (let index = 0; index < filteredProducts.length; index++) {
            if (filteredProducts[index] != null) {
              filteredProductsV2.push(filteredProducts[index])
            }
          }

          setSelectedProducts(filteredProductsV2)
        },
        [selectedProducts]
      )

      const currSelectedProductLength = currentSelectedProducts.length - 1

      useEffect(() => {
        // Set time out is used to give currentSelectedProducts a moment to populate.
        if (reviewId) {
          setTimeout(async () => {
            if (!selectedProducts.length && currentSelectedProducts.length >= currSelectedProductLength) {
              setSelectedProducts(currentSelectedProducts)
            }
          }, 100)
        }
      }, [currentSelectedProducts, selectedProducts.length, currSelectedProductLength, reviewId])

      useEffect(() => {
        if (activeProduct) {
          setActiveDrawer(true)
        }
      }, [activeProduct])

      useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" })
      }, [step])

      // Price restrict (Used to restrict the button on product next button to $60)
      let priceRestrict = null

      selectedProducts.map(product => {
        if (product != null) {
          if (product?.tags?.includes("Pick+Gift:Product")) {
            priceRestrict += Number(product?.priceRange?.maxVariantPrice?.amount)
            priceRestrict = Number(priceRestrict.toFixed(2))
          }
        }
      })

      useEffect(() => {
        const currentProducts = selectedProducts.filter(product => product)

        if (
          (priceRestrict < 60 && step === STEPS.selectBag && priceRestrict != null) ||
          (priceRestrict < 60 && step === STEPS.selectCards && priceRestrict != null) ||
          (priceRestrict < 60 && step === STEPS.custMessage && priceRestrict != null) ||
          (priceRestrict < 60 && step === STEPS.reviewBag && priceRestrict != null)
        ) {
          handleGoBack(1)
        }

        // Takes user back to select a bag if they remove it from there gift
        if (bagChecked == false && bagChecked != null) {
          handleGoBack(2)
        }

        // Takes user back to select a bag if they remove it from there gift
        if (boxChecked == false && boxChecked != null) {
          handleGoBack(0)
        }
        if (!reviewId) {
          if (!selectedProducts?.[0]) {
            handleGoBack(0)
          } else if (step === STEPS.reviewBag && currentProducts.length < 3) {
            handleGoBack()
          }
        } else {
          if (!selectedProducts?.[0] && selectedProducts.length < 3 && currentSelectedProducts.length > 3 && step != STEPS.reviewBag) {
            handleGoBack(0)
          } else if (step === STEPS.reviewBag && selectedProducts.length === 4 && currentProducts.length < 3) {
            handleGoBack()
          }
        }
      }, [
        STEPS.custMessage,
        STEPS.selectBag,
        STEPS.selectCards,
        STEPS.reviewBag,
        bagChecked,
        boxChecked,
        currentSelectedProducts.length,
        priceRestrict,
        handleGoBack,
        handleNext,
        isMedium,
        reviewId,
        selectedProducts,
        selectedProducts.length,
        step,
      ])

      const props = {
        step,
        steps: STEPS,
        locales,
        page,
        filters,
        refine,
        selectedFilter,
        selectedProducts,
        bags,
        activeProducts,
        activeProduct,
        activeDrawer,
        template,
        displaySlider,
        selectedProductCount,
        custCardInfo,
        activated,
        reviewId: currentSelectedProducts?.length >= 3 && reviewId,
        handleNext,
        handleSkip,
        handleGoBack,
        handleClickFilter,
        handleClickRefine,
        handleCloseDrawer,
        handleCardInfo,
        handleSetActivated,
        handleClickProductInfo,
        handleSelectProduct,
        handleRemoveProduct,
      }

      Component.displayName = name
      return <Component {...(props as P)} />
    }
  )

type ExternalProps = Components.ComponentProps & {
  locales: PickGift.Locales
  page: Components.PageProps
  pickGiftBags: Sanity.Collection
  pickGiftBoxes: Sanity.Collection
  pickGiftProducts: Sanity.Collection
  pickGiftCards: Sanity.Collection
  pickGiftFilter: PickGift.Item[]
  pickGiftRefine: PickGift.Item[]
  products: any
  template: any
}
type InjectedProps = {
  step: number
  steps: PickGift.Steps
  locales: PickGift.Locales
  page: Components.PageProps
  filters: PickGift.Item[]
  refine: PickGift.Item[]
  selectedFilter: PickGift.Item
  selectedProducts: PickGift.SelectableProduct[]
  cards: PickGift.SelectableProduct[]
  bags: PickGift.SelectableProduct[]
  boxes: PickGift.SelectableProduct[]
  activeProducts: PickGift.SelectableProduct[]
  activeProduct: PickGift.SelectableProduct
  activeDrawer: boolean
  template: any
  displaySlider: boolean
  custCardInfo: string
  activated: boolean
  selectedProductCount: PickGift.SelectedProductCount
  reviewId: string
  setActivated: boolean
  handleSetActivated: () => void
  handleNext: () => void
  handleSkip: () => void
  handleGoBack: () => void
  handleClickFilter: (handle: string) => void
  handleClickRefine: (handle: string) => void
  handleCloseDrawer: () => void
  handleClickProductInfo: (product: PickGift.SelectableProduct) => void
  handleSelectProduct: (product: PickGift.SelectableProduct) => void
  handleRemoveProduct: (productHandle: string) => void
  handleCardInfo: () => void
}
