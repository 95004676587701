import React, { memo, useCallback } from "react"

import * as Components from "../../../../types/components"
import { useApp } from "../../../../hooks/useApp"
import { usePickGift } from "../../../../hooks/usePickGift"
import { useRoutes } from "../../../../hooks/useRoutes"

export const withNextPickGiftPopup = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = "PickGiftPopup", activated, handleNext, locales }: ExternalProps) => {
    const { activePickGiftPopup: active, headerBounds, setActivePickGiftPopup: setActive } = useApp()
    const { pickMix } = usePickGift()
    const { linkResolver } = useRoutes()

    const { links: rawLinks } = pickMix
    const links = rawLinks.map(rawLink => linkResolver(rawLink))

    const handleActive = useCallback(() => setActive(false), [setActive])

    const handleClick = useCallback(async () => {
      handleNext()
    }, [handleNext])

    const props = {
      active,
      activated,
      handleClick,
      handleNext,
      handleActive,
      bounds: headerBounds,
      links,
      locales,
    }

    Component.displayName = name
    return <Component {...(props as P)} />
  })

type ExternalProps = Components.ComponentProps & {
  activated: any
  locales: any
  handleNext: () => void
}

type InjectedProps = {
  active: boolean
  activated: boolean
  handleActive: () => void
  bounds: any
  links: any
  locales: any
}
