import React from "react"

import { withPickGiftProgress } from "./withPickGiftProgress"
import { Wrapper, Inner, StepWrapper, Step, Line, TextWrapper, Content } from "./styledPickGiftProgress"

export const PickGiftProgress = withPickGiftProgress(
  ({ steps, stepIndex }): JSX.Element => (
    <Wrapper>
      <Inner>
        {steps.map((step, index) =>
          step.slug === "line" ? (
            <Line key={index} className={(stepIndex >= 4 && index == 1) || (stepIndex >= 4 && index == 3) ? "lineFix" : ""} />
          ) : (
            <StepWrapper
              className={
                (stepIndex == 0 && index == 6) || (stepIndex == 0 && index == 8)
                  ? "mobileHidden"
                  : (stepIndex == 2 && index == 0) || (stepIndex == 2 && index == 8)
                  ? "mobileHidden hideFirstStep"
                  : (stepIndex >= 4 && index == 0) || (stepIndex >= 4 && index == 2)
                  ? "mobileHidden"
                  : "mobileFix"
              }
              key={index}
            >
              <Step active={index === stepIndex ? "true" : index == 8 && stepIndex == 10 ? "true" : undefined} />
              <TextWrapper>
                <Content active={index === stepIndex ? "true" : index == 8 && stepIndex == 10 ? "true" : undefined}>{step.title}</Content>
                <Content active={index === stepIndex ? "true" : index == 8 && stepIndex == 10 ? "true" : undefined}>
                  {index === stepIndex ? "Select" : index == 8 && stepIndex == 10 ? "" : ""} {step.subtitle}
                </Content>
              </TextWrapper>
            </StepWrapper>
          )
        )}
      </Inner>
    </Wrapper>
  )
)
