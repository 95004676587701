import tw, { styled } from "twin.macro"

import { StyledContainer } from "../../../Styled/Container"
import { StyledButton, StyledLink } from "../../../Styled/Button"
import { StyledImage } from "../../../Styled/Image"
import { H4 } from "../../../Styled/Text"

export const Footer = tw.section`
  sticky bottom-0 inset-x-0 shadow-top py-4 md:py-5 h-20 md:h-30 z-10 bg-white visible transform translate-y-0 lg:translate-y-0 transition-all duration-500
`
export const Inner = tw.div`flex w-full h-full items-center justify-between px-2 sm:px-6`
export const ImageGroup = styled.div`
  ${tw`flex md:w-1/2 justify-end md:justify-start items-center`}

  @media only screen and (max-width: 768px) {
    width: 50%;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -webkit-justify-content: flex-start;
    justify-content: flex-end;
  }

  @media only screen and (max-width: 625px) {
    width: 50%;
  }

  @media only screen and (max-width: 510px) {
    width: 40%;
  }

  @media only screen and (max-width: 450px) {
    width: 35%;
  }

  @media only screen and (max-width: 350px) {
    width: 32%;
  }
`

export const ImageWrapper = styled.div`
  ${tw`relative w-10 h-10  md:w-15 md:h-15 lg:w-20 lg:h-20 z-1 mx-1 md:mx-1.5`}
  margin-right: 0 !important;
  margin-left: 0 !important;

  div {
    border-radius: 0;
    border-width: 0;
    --tw-border-opacity: 0;
    border-color: transparent;
  }
`
export const Image = styled(StyledImage)`
  ${tw`rounded-lg border-2 border-orange-light`}
  picture img {
    object-fit: contain !important;
    width: 83% !important;
    border-radius: 0.5rem;
    border-width: 2px;
    --tw-border-opacity: 1;
    border-color: rgba(255, 172, 39, var(--tw-border-opacity));
  }
`

export const Placeholder = tw.div`flex h-full items-center text-orange text-3xl font-medium justify-center border border border-orange rounded-lg`
export const AspectRatio = tw.div`w-full h-full`
export const ButtonGroup = tw.div`flex items-center justify-end`
export const Button = styled(StyledButton)`
  ${tw`min-w-25`}

  @media only screen and (min-width: 768px) {
    padding-left: 4rem !important;
    padding-right: 4em !important;
  }

  @media only screen and (max-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @media only screen and (max-width: 420px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: 14px;
  }
`

export const Link = styled(StyledLink)`
  ${({ center }) => (center ? tw`flex items-center mb-12 mx-auto` : "")}
`

export const LinkText = tw.span`ml-2`
export const TitleGroup = tw.div`flex md:ml-6`
export const Total = tw(H4)`text-lg md:text-4.25xl tracking-wide font-medium`
export const Amount = tw(H4)`pl-2 text-lg md:text-4.25xl tracking-wide text-black`

export const Container = styled(StyledContainer)`
  ${tw`h-full`}
  padding: 0 !important;

  .swiper-container {
    margin-left: 0;
    padding: 5px 0;
  }

  .swiper-slide {
    width: fit-content !important;
  }
`
export const Close = styled(StyledLink)`
  ${tw`absolute top-0 right-0 -mt-1.5 -mr-1.5 p-1 z-30 bg-grey-lighter rounded-full`}

  margin-right: 0.375rem;
  svg {
    ${tw`w-3`}
  }
`

export const Tooltip = styled.div`
  ${tw`absolute inline bottom-16 z-1 w-64 bg-white text-black text-left rounded-lg shadow py-4 pl-6 pr-10 cursor-default md:pointer-events-none`}

  @media only screen and (max-width: 1440px) {
    right: 13px;
    font-size: 16px;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  @media only screen and (max-width: 550px) {
    right: 13px;
    width: 13rem;
    font-size: 14px;
    padding-right: 1rem;
    padding-left: 1rem;
  }
`

export const Toolwrapper = tw.div``
