import React from "react"

import { withProductCard } from "./withProductCard"
import { StyledImage } from "../../../Styled/Image"
import { P } from "../../../Styled/Text"
import {
  Card,
  ButtonLink,
  Button,
  Content,
  ImageContent,
  Image,
  ImageContainer,
  TextContent,
  TitleGroup,
  ProductContent,
  ButtonGroup,
  Title,
  ButtonWrapper,
  ButtonWrapperLearnMore,
} from "./styledProductCard"

export const ProductCard = withProductCard(
  ({
    colour,
    handleClickInfo,
    handleClickSelect,
    handleRemove,
    handleHover,
    hover,
    images,
    title,
    price,
    type,
    withSpacing,
    isBag,
    isBox,
    isCard,
    isReview,
    highlight,
    selectedMaxQty,
    isAvailable,
    locales,
    product,
    disableButton,
  }) => (
    <Card
      onMouseEnter={() => handleHover(true)}
      onMouseLeave={() => handleHover(false)}
      withSpacing={withSpacing}
      highlight={highlight ? "true" : undefined}
    >
      <ImageContent onClick={isReview || !isAvailable ? undefined : handleClickSelect} clickable={!isReview && isAvailable ? "true" : undefined}>
        <ImageContainer colour={`${colour}-pastel`}>
          <Image hover={images[1] && hover}>
            <StyledImage alt={images[0]?.alt || title} fluid={{ ...images[0], aspectRatio: 3 / 3.6 }} />
          </Image>
          {images[1] && (
            <Image hover={!hover}>
              <StyledImage alt={images[1]?.alt || title} fluid={{ ...images[1], aspectRatio: 3 / 3.6 }} />
            </Image>
          )}
        </ImageContainer>
      </ImageContent>
      <Content>
        <TextContent>
          {title && (
            <TitleGroup>
              <Title uppercase>{title}</Title>
              {type ? <P>{type}</P> : <P dangerouslySetInnerHtml={{ __html: `&nbsp;` }} withSpacing={"md"} />}
            </TitleGroup>
          )}
          {/* {content && (
            <ProductContent withSpacing={`xs`}>
              {typeof content === "string" ? (
                <ContentGroup>
                  <ContentBulletPoint withSpacing={`none`}>{"• "}</ContentBulletPoint>
                  <P withSpacing={`none`}>{content}</P>
                </ContentGroup>
              ) : Array.isArray(content) ? (
                content.map((text, index) => (
                  <ContentGroup key={index}>
                    <ContentBulletPoint withSpacing={`none`}>{"• "}</ContentBulletPoint>
                    <P withSpacing={`none`}>{text}</P>
                  </ContentGroup>
                ))
              ) : (
                <RichText withSpacing={`none`}>{content}</RichText>
              )}
            </ProductContent>
          )} */}
          <ProductContent>
            {!isBag && !isReview && !isBox && !isCard && (
              <ButtonWrapperLearnMore>
                <ButtonLink onClick={handleClickInfo} colour="orange-outline" size="tertiary-auto" full>
                  {locales?.additionalLearnMore}
                </ButtonLink>
              </ButtonWrapperLearnMore>
            )}
          </ProductContent>
        </TextContent>
        <ButtonGroup>
          {isReview && (
            <ButtonWrapper>
              <Button onClick={handleRemove} colour="orange-outline" size="tertiary-auto" full>
                {locales?.additionalRemove}
              </Button>
            </ButtonWrapper>
          )}

          {!isReview && (
            <ButtonWrapper>
              <Button onClick={handleClickSelect} colour="orange" size="tertiary-auto" disabled={disableButton || !isAvailable} full>
                {price == 0 ? "" : `$${price} - `}{" "}
                {!isAvailable
                  ? locales?.additionalSoldOut
                  : product.tags.includes("Pick+Gift:Product") && !selectedMaxQty
                  ? locales?.addToBag
                  : product.tags.includes("Pick+Gift:Product") && selectedMaxQty
                  ? locales?.addedToBag
                  : selectedMaxQty
                  ? locales?.additionalSelected
                  : locales?.additionalSelect}
              </Button>
            </ButtonWrapper>
          )}
        </ButtonGroup>
      </Content>
    </Card>
  )
)
