import React from "react"

import { withPickGiftRefine } from "./withPickGiftRefine"
import { FormWrapper } from "./styledPickGiftRefine"
import { FormSelect } from "../../../Form/Select/FormSelect"

export const PickGiftRefine = withPickGiftRefine(
  ({ activeIndex, options, active, handleChange }): JSX.Element => (
    <FormWrapper>
      <FormSelect options={options} onChange={handleChange} value={options[activeIndex]?.value} placeholder={active} colour={"orange"} />
    </FormWrapper>
  )
)
