import React, { memo, useState, useCallback } from "react"

import * as Components from "../../types/components"
import * as Sanity from "../../types/sanity"
import { PickGift } from "../../types/pickGift"
import { useApp } from "../../hooks/useApp"
import { useRoutes } from "../../hooks/useRoutes"

export const withPickGift = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = "PickGift", location, products, page, pickGift, locales, template }: ExternalProps) => {
    const {
      config: {
        settings: { params },
      },
    } = useApp()
    const { getUrlParameter } = useRoutes()

    const reviewId = getUrlParameter(params.review, location)

    const [view, setView] = useState<View>(reviewId ? "Select" : "Landing")

    const handleNextView = useCallback((nextView: View) => {
      setView(nextView)
    }, [])

    const props = {
      locales,
      view,
      page,
      handleNextView,
      pickGiftBags: pickGift.bags,
      pickGiftBoxes: pickGift.boxes,
      pickGiftProducts: pickGift.products,
      pickGiftFilter: pickGift.filter,
      pickGiftRefine: pickGift.refine,
      pickGiftCards: pickGift.cards,
      products,
      template,
    }

    Component.displayName = name
    return <Component {...(props as P)} />
  })

type View = "Landing" | "Select"

type InjectedProps = {
  page?: Components.PageProps
  view: View
  locales: PickGift.Locales
  handleNextView: (nextView: View) => void
  pickGiftBags: Sanity.Collection
  pickGiftBoxes: Sanity.Collection
  pickGiftCards: Sanity.Collection
  pickGiftProducts: Sanity.Collection
  pickGiftFilter: PickGift.Item[]
  pickGiftRefine: PickGift.Item[]
  products: any
  template: any
}

type ExternalProps = Components.ComponentProps & {
  page?: Components.PageProps
  locales: PickGift.Locales
  products: any
  pickGift: {
    bags: Sanity.Collection
    boxes: Sanity.Collection
    cards: Sanity.Collection
    products: Sanity.Collection
    filter: PickGift.Item[]
    refine: PickGift.Item[]
  }
  template: any
}
