import tw from "twin.macro"
import styled from "@emotion/styled/macro"

import { P } from "../../../Styled/Text"

export const Wrapper = styled.div`
  ${tw`md:mx-35 pt-10 px-10 pb-20`}

  @media only screen and (max-width: 475px) {
    padding: 0;
    margin-left: -2rem;
  }
`

export const Inner = styled.div`
  ${tw`flex items-center`}

  @media only screen and (max-width: 475px) {
    .mobileHidden {
      display: none !important;
    }
    .hideFirstStep {
      margin-left: -4rem;
    }

    .mobileFix:first-of-type {
      margin-left: 6rem;
    }

    .mobileFix:last-child {
      margin-right: 6rem;
    }

    .css-19ku0ls-Line {
      padding-right: 7rem;
      margin-top: -3rem;
    }

    .lineFix {
      margin-right: -3rem;
    }

    height: 10rem;
    margin-top: -3rem;
    overflow: hidden;
    padding-bottom: 2rem;

    .css-116nl4n-StepWrapper {
      margin-top: -3rem;
    }
  }

  @media only screen and (max-width: 425px) {
    .css-19ku0ls-Line {
      padding-right: 6rem;
    }
  }

  @media only screen and (max-width: 375px) {
    .css-19ku0ls-Line {
      padding-right: 4rem;
    }
  }
`

export const StepWrapper = styled.div`
  ${tw`flex items-center relative bg-white`}
`
export const Step = styled.div<GenericProps>`
  ${tw`rounded-full transition duration-500 ease-in-out h-5 w-5 border-2 border-orange`}
  ${({ active }) => (active === "true" ? tw`bg-orange` : "")}
`
export const Line = tw.div`flex-auto border-t-2 transition duration-500 ease-in-out border-orange`

export const TextWrapper = tw.div`absolute top-0 -ml-13 text-center mt-8 w-32 font-medium`

export const Content = styled(P)<GenericProps>`
  ${tw`pt-3`}
  ${({ active }) => (active === "true" ? tw`font-bold` : "")}
`

type GenericProps = {
  active?: "true" | undefined
}
