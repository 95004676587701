import React from "react"

import { withNextPickGiftPopup } from "./withNextPickGiftPopup"
import { Notify, Inner, Popup, Button, Title } from "./styledNextPickGiftPopup"

export const NextPickGiftPopup = withNextPickGiftPopup(
  ({ bounds, handleClick, activated, locales }): JSX.Element => (
    <Notify active={activated} top={bounds?.height}>
      <Inner>
        <Popup active={activated}>
          <Title> {locales.popUpOneText} </Title>
          <Button onClick={handleClick} colour={"orange"} full={"true"} size={"primary"}>
            {locales.popUpOneButton}
          </Button>
        </Popup>
      </Inner>
    </Notify>
  )
)
