import React, { memo, useCallback } from "react"
import uuid from "react-uuid"

import * as Components from "../../../../types/components"
import { PickGift } from "../../../../types/pickGift"
import { useApp } from "../../../../hooks/useApp"
import { useCore } from "../../../../hooks/useCore"
import { usePickGift } from "../../../../hooks/usePickGift"
import { useCart } from "../../../../hooks/useCart"
import { useImage } from "../../../../hooks/useImage"

export const withPickGiftAddToCart = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = "PickGiftAddToCart", reviewId, buttonTitle, buttonDisabled, selectedProducts, custCardInfo }: ExternalProps) => {
    const {
      config: {
        settings: { customAttributeKeys },
      },
      setActivePickGiftPopup,
    } = useApp()
    const {
      helpers: { decodeShopifyId },
    } = useCore()
    const { addMultipleToCart } = useCart()
    const { handleBoxCardAnimationPopup } = usePickGift()
    const { getFluidImage } = useImage()

    const handleAdd = useCallback(async () => {
      // selectedProducts.pop()
      const selectedItems = [...selectedProducts]

      const selectedItemsImages = []
      for (let index = 0; index < selectedProducts.length; index++) {
        if (
          selectedProducts[index].tags.includes("Pick+Gift:Bag") ||
          selectedProducts[index].tags.includes("Pick+Gift:Card") ||
          selectedProducts[index].tags.includes("Pick+Gift:Box")
        ) {
          let itemIdentifier
          if (selectedProducts[index].tags.includes("Pick+Gift:Bag")) {
            itemIdentifier = "bag"
          } else if (selectedProducts[index].tags.includes("Pick+Gift:Card")) {
            itemIdentifier = "card"
          } else {
            itemIdentifier = "open box"
          }
          selectedItemsImages.push({ src: selectedProducts[index], itemIdentifier: itemIdentifier })
        }
      }

      const images = []

      for (let index = 0; index <= selectedItemsImages.length; index++) {
        if (index == selectedItemsImages.length) {
          images.push({ itemIdentifier: "closed bag", img: selectedItemsImages[0]?.src.images[selectedItemsImages[0]?.src.images.length - 2] })
          break
        }

        if (selectedItemsImages[index]?.content?.animationImage) {
          images.push({
            itemIdentifier: selectedItemsImages[index].itemIdentifier,
            img: getFluidImage(selectedItemsImages[index].content.animationImage, { maxWidth: 200 }),
          })
        } else if (selectedItemsImages[index]?.src.images?.length) {
          images.push({
            itemIdentifier: selectedItemsImages[index].itemIdentifier,
            img: selectedItemsImages[index]?.src.images[selectedItemsImages[index]?.src.images.length - 1],
          })
        } else {
          images.push(null)
        }
      }

      if (selectedItems.length < 3) {
        return
      }

      handleBoxCardAnimationPopup(images)

      const bagItem = selectedItems.shift()
      const bagVariantId = bagItem.variants?.[0]?.id

      if (!bagVariantId) {
        return
      }

      const groupedVariantId = decodeShopifyId(bagVariantId, `Variant`)
      const groupedId = reviewId || uuid()

      let attributes = [
        {
          key: customAttributeKeys.grouped,
          value: groupedId,
        },
        {
          key: customAttributeKeys.groupType,
          value: "pick + gift",
        },
        {
          key: customAttributeKeys.groupedVariantId,
          value: groupedVariantId,
        },
      ]

      if (selectedItems[selectedItems.length - 1]?.tags.includes("Pick+Gift:Card") && custCardInfo) {
        attributes = [
          ...attributes,
          {
            key: "_customer_card_text",
            value: custCardInfo,
          },
        ]
      }

      const groupedItems = selectedItems.reduce((result, item) => {
        const key = item.variants?.[0]?.id
        if (!key) {
          return result
        }

        if (result[key]) {
          result[key].quantity = result[key].quantity + 1
        } else {
          result[key] = {
            variantId: key,
            quantity: 1,
            groupedId,
            customAttributes: [
              ...attributes,
              {
                key: customAttributeKeys.groupedName,
                value: bagItem.title,
              },
              {
                key: customAttributeKeys.groupType,
                value: "pick + gift",
              },
            ],
          }
        }
        return result
      }, {})

      const input = Object.values(groupedItems) as unknown as Array<{
        variantId: string
        quantity: number
        customAttributes: Array<{ key: string; value: string }>
      }>
      input.unshift({ variantId: bagVariantId, quantity: 1, customAttributes: attributes })

      setTimeout(async () => {
        await addMultipleToCart(input, groupedId)
        setActivePickGiftPopup(true)
      }, 2000)
    }, [
      addMultipleToCart,
      customAttributeKeys.grouped,
      customAttributeKeys.groupType,
      customAttributeKeys.groupedName,
      customAttributeKeys.groupedVariantId,
      decodeShopifyId,
      getFluidImage,
      handleBoxCardAnimationPopup,
      reviewId,
      selectedProducts,
      setActivePickGiftPopup,
      custCardInfo,
    ])

    const props = {
      buttonTitle,
      buttonDisabled,
      handleAdd,
    }

    Component.displayName = name
    return <Component {...(props as P)} />
  })

type ExternalProps = Components.ComponentProps & {
  reviewId?: string
  buttonTitle: string
  buttonDisabled: boolean
  custCardInfo: string
  selectedProducts: PickGift.SelectableProduct[]
}
type InjectedProps = {
  buttonTitle: string
  handleAdd: () => Promise<void>
  buttonDisabled: boolean
}
