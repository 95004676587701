import tw, { styled } from "twin.macro"

import { StyledButton } from "../../../Styled/Button"

export const FormWrapper = styled.div`
  ${tw`w-full md:flex md:justify-end mt-2 mb-5 md:mb-2`}

  select {
    color: #3f3b29;
  }

  @media only screen and (max-width: 768px) {
    margin-top: -25px;

    select {
      padding-top: 0;
    }
  }
`

export const ButtonWrapper = tw.div`flex w-full flex-wrap lg:mx-20 lg:w-5/6 justify-center items-center mt-10`
export const Button = tw(StyledButton)`my-1 mx-1`
