import React from "react"

import { withPickGift } from "./withPickGift"
import { PickGiftLanding } from "./Landing/PickGiftLanding"
import { PickGiftSelect } from "./Select/PickGiftSelect"

export const PickGift = withPickGift(
  ({
    handleNextView,
    page,
    view,
    locales,
    pickGiftBoxes,
    pickGiftBags,
    pickGiftCards,
    pickGiftProducts,
    pickGiftFilter,
    pickGiftRefine,
    products,
    template,
  }): JSX.Element => {
    switch (view) {
      case "Landing":
        return <PickGiftLanding locales={locales} handleNext={() => handleNextView("Select")} page={page} />
      case "Select":
        return (
          <PickGiftSelect
            locales={locales}
            page={page}
            pickGiftBags={pickGiftBags}
            pickGiftBoxes={pickGiftBoxes}
            pickGiftProducts={pickGiftProducts}
            pickGiftCards={pickGiftCards}
            pickGiftFilter={pickGiftFilter}
            pickGiftRefine={pickGiftRefine}
            products={products}
            template={template}
          />
        )
      default:
        return null
    }
  }
)
