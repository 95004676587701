import React from "react"
import SwiperCore, { Autoplay, Mousewheel, Navigation } from "swiper"

import { withProductsSlides } from "./withProductSlides"
import { ProductCard } from "../Card/ProductCard"
import { Container, ProductCardContainer } from "./styledProductsSlides"

SwiperCore.use([Autoplay, Mousewheel, Navigation])

export const ProductsSlides = withProductsSlides(
  ({ items, selectedProductCount, handleClickProductInfo, handleSelectProduct, isBag, isReview, locales }): JSX.Element => (
    <>
      <Container>
        {items?.map((item, index) => (
          <ProductCardContainer key={index}>
            <ProductCard
              product={item}
              selectedCount={selectedProductCount[item.id]}
              withSpacing={"default"}
              handleClickProductInfo={handleClickProductInfo}
              handleClickSelectProduct={handleSelectProduct}
              isBag={isBag}
              isReview={isReview}
              locales={locales}
            />
          </ProductCardContainer>
        ))}
      </Container>
    </>
  )
)
