import React from "react"

import { withPickGiftSelect } from "./withPickGiftSelect"
import { PickGiftSelectHeader } from "./Header/PickGiftSelectHeader"

import { PickGiftProgress } from "./Progress/PickGiftProgress"
import { NextPickGiftPopup } from "./NextPopup/NextPickGiftPopup"
import { PickGiftSelectFilter } from "./Filter/PickGiftSelectFilter"
import { PickGiftRefine } from "./Refine/PickGiftRefine"
import { PickGiftSelectFooter } from "./Footer/PickGiftSelectFooter"
import { ProductsSlides } from "./Slides/ProductsSlides"
import { ProductCard } from "./Card/ProductCard"
import { ProductDrawer } from "./Drawer/ProductDrawer"
import { StyledColumn } from "../../Styled/Column"
import { StyledRow } from "../../Styled/Row"
import { Inner, Container, ContentWrapper, MessageHeading, MessageSubheading, Div } from "./styledPickGiftSelect"

export const PickGiftSelect = withPickGiftSelect(
  ({
    step,
    steps,
    locales,
    page,
    filters,
    refine,
    selectedFilter,
    selectedProducts,
    activeProducts,
    activeProduct,
    activeDrawer,
    template,
    displaySlider,
    custCardInfo,
    selectedProductCount,
    reviewId,
    activated,
    handleSetActivated,
    handleNext,
    handleSkip,
    handleGoBack,
    handleClickFilter,
    handleCloseDrawer,
    handleClickRefine,
    handleClickProductInfo,
    handleSelectProduct,
    handleCardInfo,
    handleRemoveProduct,
  }): JSX.Element => (
    <>
      <PickGiftSelectHeader locales={locales} page={page} />
      <ContentWrapper condensed={`md>`} width={`lg`}>
        <NextPickGiftPopup handleNext={handleNext} activated={activated} locales={locales} />
        <PickGiftProgress locales={locales} step={step} />
        {step === steps.selectProducts && <PickGiftSelectFilter items={filters} selectedFilter={selectedFilter} handleClick={handleClickFilter} />}
      </ContentWrapper>
      {step === steps.custMessage ? (
        <Container>
          <MessageHeading> {locales.custCardMessageHeading} </MessageHeading>
          <MessageSubheading>{locales.custCardMessageDescription} </MessageSubheading>
          <Div>
            <textarea className="messageTextarea" onChange={handleCardInfo} placeholder="Start typing here..." value={custCardInfo}></textarea>
          </Div>
        </Container>
      ) : (
        <Container width={`default`}>
          <Inner>
            {step === steps.selectProducts && <PickGiftRefine items={refine} selectedFilter={selectedFilter} handleClick={handleClickRefine} />}
            <StyledRow gutter={`sm`} justify={step !== steps.reviewBag ? "center" : "start"} items={`stretch`} withWrap>
              {displaySlider ? (
                <ProductsSlides
                  items={activeProducts}
                  locales={locales}
                  selectedProductCount={selectedProductCount}
                  handleClickProductInfo={handleClickProductInfo}
                  handleSelectProduct={handleSelectProduct}
                />
              ) : (
                activeProducts.map((product, index) => (
                  <StyledColumn key={index} gutter={`sm`} items={`start`} justify={`end`} vertical width={`1/2 lg:1/4`}>
                    <ProductCard
                      product={product}
                      withSpacing={`lg`}
                      handleClickProductInfo={handleClickProductInfo}
                      handleClickSelectProduct={handleSelectProduct}
                      handleRemoveProduct={handleRemoveProduct}
                      selectedCount={selectedProductCount[product.id]}
                      isBox={step === steps.selectBox}
                      isBag={step === steps.selectBag}
                      isCard={step === steps.selectCards}
                      isReview={step === steps.reviewBag}
                      locales={locales}
                    />
                  </StyledColumn>
                ))
              )}
            </StyledRow>
          </Inner>
        </Container>
      )}
      <PickGiftSelectFooter
        selectedProducts={selectedProducts}
        handleRemoveProduct={handleRemoveProduct}
        handleNext={handleNext}
        handleSkip={handleSkip}
        handleGoBack={handleGoBack}
        custCardInfo={custCardInfo}
        handleSetActivated={handleSetActivated}
        locales={locales}
        step={step}
        steps={steps}
        reviewId={reviewId}
      />
      <ProductDrawer
        active={activeDrawer}
        activeProduct={activeProduct}
        template={template}
        handleClose={handleCloseDrawer}
        handleClickSelectProduct={handleSelectProduct}
      />
    </>
  )
)
