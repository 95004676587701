import React from "react"

import { withPickGiftSelectHeader } from "./withPickGiftSelectHeader"
import { Breadcrumbs } from "../../../Breadcrumbs/Breadcrumbs"

import { PlaceholderHero } from "./styledPickGiftSelectHeader"

export const PickGiftSelectHeader = withPickGiftSelectHeader(
  ({ page }): JSX.Element => (
    <>
      <PlaceholderHero />
      <Breadcrumbs colour={"dark"} page={page} />
    </>
  )
)
