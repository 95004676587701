import React from "react"

import { withPickGiftAddToCart } from "./withPickGiftAddToCart"
import { Button } from "./styledPickGiftAddToCart"

export const PickGiftAddToCart = withPickGiftAddToCart(
  ({ buttonTitle, handleAdd, buttonDisabled }): JSX.Element => (
    <Button colour={`orange`} onClick={handleAdd} size={`secondary`} title={buttonTitle} disabled={buttonDisabled}>
      {buttonTitle}
    </Button>
  )
)
