import tw, { styled } from "twin.macro"

import { StyledButton } from "../../../Styled/Button"

export const FormWrapper = styled.div`
  ${tw`w-full justify-between items-center mt-10`}

  @media only screen and (max-width: 475px) {
    padding-right: 1.5rem;
  }
`
export const ButtonWrapper = tw.div`flex w-full flex-wrap lg:mx-20 lg:w-5/6 justify-center items-center mt-10`
export const Button = tw(StyledButton)`my-1 mx-1`
