import tw, { css, styled } from "twin.macro"

import { GlobalStyles } from "../../../../Styled/Global"
import { StyledButton } from "../../../../Styled/Button"

export const Bar = styled.section`
  ${tw`sticky bottom-0 inset-x-0 shadow-top lg:shadow-lg py-5 z-5 invisible transform translate-y-full transition-all `}
  ${({ active }) => active && tw`visible translate-y-0  lg:translate-y-0`}
  ${({ colour }) => colour && GlobalStyles.background[colour]}
  ${({ top }) =>
    top &&
    css`
      @media (min-width: 1024px) {
        top: ${top}px;
      }
    `}
`
export const Button = tw(StyledButton)``
