import React, { memo, useMemo } from "react"

import * as Components from "../../../../types/components"
import { PickGift } from "../../../../types/pickGift"
import slugify from "slugify"

export const withPickGiftProgress = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(({ name = "PickGiftProgress", locales, step }: ExternalProps) => {
    const steps = useMemo(
      () => [
        {
          title: `${locales?.additionalStep?.toLocaleUpperCase()} 1`,
          subtitle: locales?.additionalStepOne,
          slug: slugify(locales?.additionalStepOne, "-"),
        },
        {
          slug: "line",
        },
        {
          title: `${locales?.additionalStep?.toLocaleUpperCase()} 2`,
          subtitle: locales?.additionalStepTwo,
          slug: slugify(locales?.additionalStepTwo, "-"),
        },
        {
          slug: "line",
        },
        {
          title: `${locales?.additionalStep?.toLocaleUpperCase()} 3`,
          subtitle: locales?.additionalStepThree,
          slug: slugify(locales?.additionalStepThree, "-"),
        },
        {
          slug: "line",
        },
        {
          title: `${locales?.additionalStep?.toLocaleUpperCase()} 4`,
          subtitle: locales?.additionalStepFour,
          slug: slugify(locales?.additionalStepFour, "-"),
        },
        {
          slug: "line",
        },
        {
          title: `${locales?.additionalStep?.toLocaleUpperCase()} 5`,
          subtitle: locales?.additionalStepFive,
          slug: slugify(locales?.additionalStepFive, "-"),
        },
      ],
      [
        locales?.additionalStep,
        locales?.additionalStepOne,
        locales?.additionalStepThree,
        locales?.additionalStepTwo,
        locales?.additionalStepFour,
        locales?.additionalStepFive,
      ]
    )

    let stepIndex

    if (step * 2 === 8) {
      stepIndex = 6
    } else {
      stepIndex = step * 2
    }

    const props = {
      steps,
      stepIndex,
    }

    Component.displayName = name
    return <Component {...(props as P)} />
  })

type Step = {
  title?: string
  subtitle?: string
  slug: string
}

type ExternalProps = Components.ComponentProps & {
  step: number
  locales: PickGift.Locales
}
type InjectedProps = {
  steps: Step[]
  stepIndex: number
}
