import React, { useState, memo, useCallback, useMemo } from "react"

import * as Components from "../../../../types/components"
import { PickGift } from "../../../../types/pickGift"
import { useDom } from "../../../../hooks/useDom"
import { usePickGift } from "../../../../hooks/usePickGift"
import { useImage } from "../../../../hooks/useImage"
// import { useApp } from "../../../../hooks/useApp"

export const withPickGiftSelectFooter = <P extends InjectedProps>(Component: React.ComponentType<P>) =>
  memo(
    ({
      name = "PickGiftSelectFooter",
      step,
      steps,
      locales,
      selectedProducts,
      reviewId,
      custCardInfo,
      handleSetActivated,
      handleRemoveProduct,
      handleNext,
      handleSkip,
      handleGoBack,
    }: ExternalProps) => {
      const {
        dom: { isSmall, isMedium, isExtraLarge },
      } = useDom()

      // const {
      //   config: {
      //     settings: { customAttributeKeys },
      //   },
      //   setActivePickGiftPopup,
      // } = useApp()

      const { handleAnimationPopup } = usePickGift()
      const { getFluidImage } = useImage()

      const [active, setActive] = useState(false)

      // Calculates the total price
      let totalPrice = 0

      // Price restrict (Used to restrict the button on product next button to $60)
      let priceRestrict = 0

      selectedProducts.map(product => {
        if (product != null) {
          totalPrice += Number(product?.priceRange?.maxVariantPrice?.amount)
          totalPrice = Number(totalPrice.toFixed(2))

          if (product?.tags?.includes("Pick+Gift:Product")) {
            priceRestrict += Number(product?.priceRange?.maxVariantPrice?.amount)
            priceRestrict = Number(priceRestrict.toFixed(2))
          }
        }
      })

      let bagCheck = false
      let cardCheck = false
      let cardSelector = false

      for (let index = 0; index < selectedProducts?.length; index++) {
        if (selectedProducts[index]?.tags.includes("Pick+Gift:Bag")) {
          bagCheck = true
        }

        if (selectedProducts[index]?.tags.includes("Pick+Gift:Card")) {
          cardCheck = true
          cardSelector = true
        }
      }

      const buttonDisabled = useMemo(() => {
        const currentSelectedProducts = selectedProducts.filter(p => p)
        let disabled = true
        if (step === steps.selectBox && selectedProducts?.[0]) {
          disabled = false
        } else if (step === steps.selectProducts && priceRestrict >= 60) {
          disabled = false
        } else if (step === steps.selectBag && bagCheck === true) {
          disabled = false
        } else if (step === steps.selectCards && cardCheck === true) {
          disabled = false
        } else if (step === steps.reviewBag && currentSelectedProducts.length > 2) {
          disabled = false
        } else if (step === steps.custMessage && currentSelectedProducts.length > 2) {
          disabled = false
        }
        return disabled
      }, [
        bagCheck,
        cardCheck,
        priceRestrict,
        steps.custMessage,
        steps.selectBox,
        steps.selectCards,
        selectedProducts,
        step,
        steps.reviewBag,
        steps.selectBag,
        steps.selectProducts,
      ])

      const displayImages = useMemo(
        () => isExtraLarge || (!isExtraLarge && step === steps.selectProducts),
        [isExtraLarge, step, steps.selectProducts]
      )

      const displayNextButton = useMemo(
        () => step === steps.selectBox || step === steps.selectBag || step === steps.custMessage || step === steps.selectProducts,
        [step, steps.selectBox, steps.selectBag, steps.custMessage, steps.selectProducts]
      )

      const displayCardNextButton = useMemo(
        () => step === steps.selectCards || (isMedium && step === steps.selectCards),
        [isMedium, step, steps.selectCards]
      )

      const hidePrice = useMemo(
        () =>
          step === steps.selectBox ||
          step === steps.selectProducts ||
          step === steps.selectBag ||
          step === steps.selectCards ||
          step === steps.custMessage ||
          step === steps.reviewBag,
        [step, steps.selectProducts, steps.custMessage, steps.reviewBag, steps.selectBag, steps.selectBox, steps.selectCards]
      )

      const displayBackButton = useMemo(
        () =>
          step === steps.selectProducts ||
          step === steps.selectBag ||
          step === steps.selectCards ||
          step === steps.custMessage ||
          step === steps.reviewBag,
        [step, steps.reviewBag, steps.selectProducts, steps.custMessage, steps.selectBag, steps.selectCards]
      )
      const displayAddToCartButton = useMemo(() => step === steps.reviewBag, [step, steps.reviewBag])
      const displayTotal = useMemo(
        () =>
          step === steps.reviewBag ||
          step === steps.selectProducts ||
          step === steps.selectBox ||
          step === steps.selectBag ||
          step === steps.selectCards ||
          step === steps.custMessage,
        [step, steps.reviewBag, steps.selectCards, steps.selectBag, steps.custMessage, steps.selectBox, steps.selectProducts]
      )

      const AddToCartTitle = useMemo(
        () => (!isSmall ? "Add to cart" : reviewId ? locales?.additionalUpdateCart : locales?.additionalAddToCart),
        [isSmall, locales?.additionalAddToCart, locales?.additionalUpdateCart, reviewId]
      )

      const placeholderItems = useMemo(() => {
        const startingNumber = selectedProducts.length
        return Array.from({ length: startingNumber - startingNumber }, (_, i) => i + startingNumber)
      }, [selectedProducts.length])

      const handleClickBack = useCallback(
        e => {
          e.preventDefault()
          handleGoBack()
        },
        [handleGoBack]
      )

      const handleItem = useCallback(
        event => {
          event.stopPropagation()

          if (step === steps.selectProducts && event?.type == "mouseenter") {
            setActive(true)
          } else {
            setActive(false)
          }
        },
        [step, steps]
      )

      // eslint-disable-next-line
      let images = []
      const handleClick = useCallback(async () => {
        if (step === steps.selectProducts) {
          const selectedItems = [...selectedProducts]

          for (let index = 0; index <= selectedItems.length; index++) {
            // Stops any cards or bags showing in the first animation
            if (selectedItems[index]?.tags?.includes("Pick+Gift:Card") || selectedItems[index]?.tags?.includes("Pick+Gift:Bag")) {
              continue
            }

            if (index == selectedItems.length) {
              images.push(selectedItems[0].images[selectedItems[0].images.length - 2])
              break
            }

            if (selectedItems[index]?.content?.animationImage) {
              images.push(getFluidImage(selectedItems[index].content.animationImage, { maxWidth: 200 }))
            } else if (selectedItems[index].images?.length) {
              images.push(selectedItems[index].images[selectedItems[index].images.length - 1])
            } else {
              images.push(null)
            }
          }

          await handleAnimationPopup(images)

          setTimeout(() => {
            handleSetActivated()
          }, 2500)
        } else {
          handleNext()
        }
        // handleSetActivated()
        // handleNext()
      }, [handleNext, step, steps.selectProducts, selectedProducts, handleAnimationPopup, getFluidImage, handleSetActivated, images])

      const handleClickSkip = useCallback(() => {
        handleSkip()
      }, [handleSkip])

      // reverses the order of the selected products to allow the selected products carousel to show the most recent product added.
      // Also works in tandem with SwiperJS carousels "dir={rtl}".
      const selectedProductsReversed = []

      selectedProducts.forEach(element => {
        selectedProductsReversed.unshift(element)
      })

      const props = {
        locales,
        selectedProducts,
        selectedProductsReversed,
        buttonDisabled,
        displayImages,
        displayNextButton,
        displayCardNextButton,
        hidePrice,
        displayBackButton,
        displayAddToCartButton,
        displayTotal,
        placeholderItems,
        AddToCartTitle,
        isMedium,
        totalPrice,
        reviewId,
        custCardInfo,
        active,
        cardSelector,
        step,
        handleRemoveProduct,
        handleClick,
        handleClickSkip,
        handleClickBack,
        handleItem,
      }

      Component.displayName = name
      return <Component {...(props as P)} />
    }
  )

type ExternalProps = Components.ComponentProps & {
  step: number
  steps: PickGift.Steps
  locales: PickGift.Locales
  selectedProducts: PickGift.SelectableProduct[]
  reviewId?: string
  custCardInfo: any
  handleSetActivated: () => void
  handleRemoveProduct: (productHandle: string) => void
  handleGoBack: () => void
  handleNext: () => void
  handleSkip: () => void
}

type InjectedProps = {
  locales: PickGift.Locales
  selectedProducts: PickGift.SelectableProduct[]
  buttonDisabled: boolean
  displayImages: boolean
  displayNextButton: boolean
  displayCardNextButton: boolean
  hidePrice: boolean
  displayBackButton: boolean
  displayAddToCartButton: boolean
  displayTotal: boolean
  placeholderItems: number[]
  AddToCartTitle: string
  isMedium: boolean
  reviewId?: string
  active: boolean
  custCardInfo: any
  totalPrice: number
  cardSelector: any
  selectedProductsReversed: any
  step: any
  handleRemoveProduct: (productHandle: string) => void
  priceCreate()
  handleClick: () => void
  handleClickSkip: () => void
  handleClickBack: () => void
  handleItem: (event) => void
}
